import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Helmet } from 'react-helmet';

import styled from 'styled-components';

import '../globalStyles.css';
import Layout from '../components/Layout';

const AppsWrapper = styled.div`
  background-color: var(--color-dark-grey);
  flex-grow: 1;
`;

const AppsContainer = styled.div`
  max-width: 1920px;
  width: 80%;
  margin: auto;

  @media (max-width: 700px) {
    width: 90%;
  }

`;

const AppsHeader = styled.div`
  color: var(--color-white);
  display: flex;
  padding-top: 8%;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

const Headline = styled.h1`
  white-space: pre-wrap;
  width: 40%;
  font-size: 60px;
  margin-top: -9px;

  @media (max-width: 1370px) {
    font-size: 50px;
  }

  @media (max-width: 1023px) {
    width: 100%;
    white-space: normal;
    margin-bottom: -20px;
    margin-top: 0;
  }

  @media (max-width: 550px) {
    font-size: 29px;
  }

`;

const Subtext = styled.h2`
  font-size: 20px;
  font-weight: 600;
  white-space: pre-wrap;
  width: 60%;
  margin-top: 0;
  
  & p {
    margin-top: 0;
  }

  @media (max-width: 1370px) {
    font-size: 17px;
  }

  @media (max-width: 1023px) {
    width 100%;
    margin-top: 3%;
  }

  @media (max-width: 800px) {
    margin-top: 5%;
  }

  @media (max-width: 550px) {
    white-space: normal;
    font-size: 15px;
    margin-top: 8%;
  }

`;

const DownloadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 5%;
  padding-bottom: 15%;
  flex-wrap: wrap;
  
  @media (max-width: 1370px) {
    padding-top: 0;
  }
`;

const DownloadSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1370px) {
    flex: 50%;
    padding-top: 10%;
  }

  @media (max-width: 550px) {
    flex: 100%;
    padding-top: 25%;

    &:first-child {
      padding-top: 18%;
    }
  }

  & .button, a {
    text-decoration: none;
    color: var(--color-white);
    font-size: 18px;
    width: 270px;
    text-align: center;
    padding: 7px 0;

    @media (max-width: 1500px) {
      font-size: 17px;
      width: 250px;
    }

    @media (max-width: 700px) {
      font-size: 15px;
      width: 230px;
    }
  }

  &:first-child {

    & div {
      display: flex;
      margin-top: 10px;
      width: 270px;
      justify-content: space-between;

      @media (max-width: 1500px) {
        width: 250px;
      }

      @media (max-width: 700px) {
        width: 230px;
        flex-direction: column;
        gap: 8px;
      }

      & .button {
        text-decoration: none;
        font-size: 12px;
        font-weight: 700;
        width: 48%;

        @media (max-width: 1500px) {
          font-size: 11px;
        }

        @media (max-width: 700px) {
          width: 60%;
          margin: auto;
        }
      }
    }  
  }
`;

const Icon = styled.img`
  height: 100px;
  padding-bottom: 40px;

  @media (max-width: 1500px) {
    height: 80px;
  }

  @media (max-width: 1350px) {
    padding-bottom: 25px;
  }

  @media (max-width: 700px) {
    height: 65px;
  }

  @media (max-width: 550px) {
  }
`;

export default class Apps extends Component {
  static propTypes = {
    pageContext: PropTypes.object,
    data: PropTypes.object,
  };

  render() {
    const { data, pageContext } = this.props;

    let canonicalLinkPrefix;

    if (pageContext.locale === 'en') {
      canonicalLinkPrefix = 'https://presono.com/en';
    } else {
      canonicalLinkPrefix = 'https://presono.com';
    }

    return (
      <Layout currentPath={pageContext.originalPath}>
        <Helmet>
          <title>{`${data?.mdx?.frontmatter?.title} | presono`}</title>
          <meta
            name="description"
            content={data?.mdx?.frontmatter?.snippetTextApps}
          />

          <link rel="canonical" href={canonicalLinkPrefix + pageContext.originalPath} />

          <meta property="og:title" content={data?.mdx?.frontmatter?.title} />
          <meta property="og:description" content={data?.mdx?.frontmatter?.snippetTextApps} />
          {/* <meta property="og:image" content={`https://d1ehx2ewtrb6s6.cloudfront.net${data?.mdx?.frontmatter?.}`} /> */}
          <meta name="twitter:title" content={data?.mdx?.frontmatter?.title} />
          <meta name="twitter:description" content={data?.mdx?.frontmatter?.snippetTextApps} />
          {/* <meta name="twitter:image" content={`https://d1ehx2ewtrb6s6.cloudfront.net${data?.mdx?.frontmatter?.}`} /> */}
        </Helmet>
        <AppsWrapper>
          <AppsContainer>
            <AppsHeader>
              <Headline>
                {data?.mdx?.frontmatter?.appsHeadline}
              </Headline>
              <Subtext>
                <MDXRenderer>{data?.mdx?.frontmatter?.appsSubtext}</MDXRenderer>
              </Subtext>
            </AppsHeader>
            <DownloadWrapper>
              {data?.mdx?.frontmatter?.appsDownloadGrid?.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <DownloadSection key={index}>
                  <a
                    href={item?.downloadLinkApp}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon src={item?.appsDownloadIcon} alt={item?.altAttributeAppsDownloadIcon} />
                  </a>
                  <a
                    href={item?.downloadLinkApp}
                    className="button"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item?.textDownloadButton}
                  </a>
                  <div>
                    { item?.downloadLinkAppButton2 && (
                      <a
                        href={item?.downloadLinkAppButton2}
                        className="button"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item?.textDownloadButton2}
                      </a>
                    )}
                    { item?.downloadLinkAppButton3 && (
                      <a
                        href={item?.downloadLinkAppButton3}
                        className="button"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item?.textDownloadButton3}
                      </a>
                    )}
                  </div>
                </DownloadSection>
              ))}
            </DownloadWrapper>
          </AppsContainer>
        </AppsWrapper>
      </Layout>
    );
  }
}

export const query = graphql`
  query AppsQuery($locale: String!) {
    mdx(
      fields: { locale: { eq: $locale } }
      frontmatter: { layout: { eq: "apps" } }
    ) {
      frontmatter {
        title
        snippetTextApps
        appsHeadline
        appsSubtext
        appsDownloadGrid {
          appsDownloadIcon
          altAttributeAppsDownloadIcon
          textDownloadButton
          downloadLinkApp
          textDownloadButton2
          downloadLinkAppButton2
          textDownloadButton3
          downloadLinkAppButton3
        }
      }
    }
  }
`;
